import { api } from "@/api/axios-base";
import { getItem } from "@/api/api-methods";

export const fetchTypesEmployee = async ({
  dato = null,
  institutionId = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : "";
    const datoFilter = dato ? `&dato=${dato}` : "";
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : "";
    const limitFilter = limit !== null ? `&limit=${limit}` : "";
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : "";
    const response = await getItem(
      "/app-personas/filters/tipo-empleado?".concat(
        institutionFilter,
        datoFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: "No se pudieron obtener los tipos de empleados " + e,
        count: 0,
      };
    }
    // console.log("Estatus", results);
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error("Error al obtener los tipos de empleados ", error);
    return {
      ok: false,
      message: "Error al intentar obtener los tipos de empleados " + error,
      count: 0,
    };
  }
};
/*export const fetchEmployeeTypeByEI = async (institutionId) => {
    try {
        //
        //const response = await api.get(`/personas/api/categoria-proveedor-by-ie/?id_institucion=${institutionId}`)
        const response = await api.get(`/app-personas/filters/tipo-empleado?institucion_educativa=${institutionId}`)
        const responseData = await response.data
        if (response.status === 200) return responseData;
    } catch (error) {
        console.error('Error al intentar obtener categorias de proveedor por institucion educativa', error);
    }
};*/
export const createEmployeeType = async (employeeData, institutionId) => {
  try {
    const response = await api.post("/app-personas/tipo-empleado", {
      dato: employeeData, //this.employeeType.dato,
      institucion_educativa: institutionId, //this.employeeType.id_institucion_educativa
    });
    const responseData = await response.data;
    if (responseData.e) {
      console.error("Ocurrió un error, no se pudo crear el tipo de empleado.");
    }
  } catch (error) {
    console.error("Error al intentar crear tipo de empleado", error);
    console.error("Error al intentar crear tipo de empleado");
  }
};
export const updateEmployeeType = async (
  typeEmployeeId,
  employeeData,
  institutionId
) => {
  try {
    const response = await api.put(
      `/app-personas/tipo-empleado/${typeEmployeeId}`,
      {
        dato: employeeData,
        institucion_educativa: institutionId,
      }
    );
    const responseData = await response.data;
    if (responseData.errors) {
      console.error(
        "Ocurrió un error, no se pudo actualizar la categoria de proveedor"
      );
    } else {
      return responseData;
    }
  } catch (error) {
    return undefined;
  }
};
export const deleteEmployeeType = async (typeId) => {
  try {
    const response = await api.delete(`/app-personas/tipo-empleado/${typeId}`);
    const responseData = await response.data;
    if (responseData.errors) {
      console.error(
        "Ocurrió un error, no se pudo eliminar la categoria de proveedor"
      );
    }
  } catch (error) {
    return undefined;
  }
};
